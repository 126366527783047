<template>
    <j-navbar></j-navbar>
    <j-toast />
    <div class="content">
      <router-view />
    </div>
    <j-footer></j-footer>
    <ConfirmDialog></ConfirmDialog>
    <cookie-banner></cookie-banner>
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";
import JFooter from "@/components/JFooter.vue";
import JNavbar from "@/components/JNavbar.vue";
import JToast from "@/components/toast/JToast.vue";
import CookieBanner from "@/components/CookieBanner.vue";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
  name: "App",
  components: {
    JToast,
    ConfirmDialog,
    JNavbar,
    JFooter,
    CookieBanner,
  },
};
</script>

<style scoped lang="scss">
.content {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
}
</style>
