<template>
    <h1>Pannello di amministrazione</h1>
    <div class="p-grid">
        <div class="p-col-12 p-md-6">
            <JHomeCard title="Termini" skeletonHeight="2rem" :loading="entriesDataLoading"
                       :error="entriesDataError" @onViewListClick="onEntriesClick">
                <template #content>
                    <p class="p-mt-0 p-mb-2">
                        Sono presenti <strong>{{ entriesData.total }}</strong> termini,
                        <strong>{{ entriesData.translated }}</strong> dei quali sono stati tradotti.
                    </p>
                </template>
                <template #afterContent>
                    <Chart type="doughnut" :data="entriesChart"/>
                </template>
            </JHomeCard>
        </div>
        <div class="p-col-12 p-md-6" v-if="isUserAdmin">
            <JHomeCard title="Utenti" skeletonHeight="2rem" :loading="usersDataLoading"
                       :error="usersDataError" @onViewListClick="onUsersClick">
                <template #content>
                    <p class="p-mt-0 p-mb-2">
                        Sono presenti <strong>{{ usersData.total }}</strong> utenti,
                        <strong>
                            {{ usersData.compilers + usersData.reviewers + usersData.admins }}
                        </strong>
                        dei quali hanno accesso al back-office.
                    </p>
                </template>
                <template #afterContent>
                    <Chart type="doughnut" :data="usersChart"/>
                </template>
            </JHomeCard>
        </div>
        <div class="p-col-12 p-md-6" v-if="isUserAdmin">
            <JHomeCard title="Tag" skeletonHeight="2rem" :loading="tagsDataLoading"
                       :error="tagsDataError" @onViewListClick="onTagsClick">
                <template #content>
                    <p class="p-mt-0 p-mb-2">
                        Sono presenti <strong>{{ tagsData.total }}</strong> tag.
                    </p>
                </template>
            </JHomeCard>
        </div>
        <div class="p-col-12 p-md-6" v-if="isUserAdmin">
            <JHomeCard title="Collaboratori" skeletonHeight="2rem"
                       :loading="collaboratorsDataLoading"
                       :error="collaboratorsDataError"
                       @onViewListClick="onCollaboratorsClick">
                <template #content>
                    <p class="p-mt-0 p-mb-2">
                        Sono presenti <strong>{{ collaboratorsData.total }}</strong> collaboratori,
                        di cui <strong>{{ collaboratorsData.active }}</strong> attivi.
                    </p>
                </template>
            </JHomeCard>
        </div>
        <div class="p-col-12 p-md-6" v-if="!isUserReviewer">
            <JHomeCard title="Assignment" skeletonHeight="2rem" :loading="assignmentDataLoading"
                       :error="assignmentsDataError" @onViewListClick="onAssignmentsClick">
                <template #content>
                    <div class="p-mt-0 p-mb-2">
                        <p v-if="assignmentsData.total > 1" class="p-m-0">
                            Hai <strong>{{ assignmentsData.total }}</strong> termini assegnati.
                        </p>
                        <p v-else-if="assignmentsData.total === 1" class="p-m-0">
                            Hai un termine assegnato.
                        </p>
                    </div>
                </template>
                <template #afterContent>
                    <Chart type="doughnut" :data="assignmentsChart"/>
                </template>
            </JHomeCard>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Chart from "primevue/chart";
import JHomeCard from "@/components/admin/JHomeCard.vue";
import EntryStatus from "@/enums/entryStatus";
import UserRole from "@/enums/userRole";
import AssignmentStatus from "@/enums/assignmentStatus";

export default {
    name: "Home",
    components: {
        Chart,
        JHomeCard,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const isUserReviewer = computed(() => store.getters.hasRoleOrUpper("reviewer"));
        const isUserAdmin = computed(() => store.getters.hasRoleOrUpper("admin"));

        const entriesData = ref({
            total: 0,
            deleted: 0,
            toTranslate: 0,
            pending: 0,
            translated: 0,
        });
        const entriesChart = computed(() => ({
            labels: Object.values(EntryStatus).map((status) => status.label),
            datasets: [{
                data: [
                    entriesData.value.deleted,
                    entriesData.value.toTranslate,
                    entriesData.value.pending,
                    entriesData.value.translated,
                ],
                backgroundColor: ["#607d8b", "#ac0033", "#F0D679", "#138762"],
            }],
        }));
        const entriesDataLoading = ref(false);
        const entriesDataError = ref(null);
        const usersData = ref({
            total: 0,
            users: 0,
            compilers: 0,
            reviewers: 0,
            admins: 0,
        });
        const usersChart = computed(() => ({
            labels: Object.values(UserRole).map((role) => role.label),
            datasets: [{
                data: [
                    usersData.value.admins,
                    usersData.value.reviewers,
                    usersData.value.compilers,
                    usersData.value.users,
                ],
                backgroundColor: ["#607d8b", "#ac0033", "#f0D679", "#138762"],
            }],
        }));
        const usersDataLoading = ref(false);
        const usersDataError = ref(null);
        const tagsData = ref({
            total: 0,
        });
        const tagsDataLoading = ref(false);
        const tagsDataError = ref(null);
        const collaboratorsData = ref({
            total: 0,
            active: 0,
        });
        const collaboratorsDataLoading = ref(false);
        const collaboratorsDataError = ref(null);

        const assignmentDataLoading = ref(false);
        const assignmentsDataError = ref(null);
        const assignmentsData = ref({
            total: 0,
            assigned: 0,
            reviewing: 0,
            refused: 0,
            completed: 0,
        });
        const assignmentsChart = computed(() => ({
            labels: Object.values(AssignmentStatus).map((state) => state.label),
            datasets: [{
                data: [
                    assignmentsData.value.assigned,
                    assignmentsData.value.reviewing,
                    assignmentsData.value.refused,
                    assignmentsData.value.completed,
                ],
                backgroundColor: ["#607d8b", "#f0D679", "#ac0033", "#138762"],
            }],
        }));

        const onEntriesClick = () => {
            router.push({ name: "admin.entries" });
        };
        const onUsersClick = () => {
            router.push({ name: "admin.users" });
        };
        const onTagsClick = () => {
            router.push({ name: "admin.tags" });
        };
        const onCollaboratorsClick = () => {
            router.push({ name: "admin.collaborators" });
        };
        const onAssignmentsClick = () => {
            router.push({ name: "assignments" });
        };

        const refreshEntriesStats = async () => {
            try {
                entriesDataLoading.value = true;
                entriesDataError.value = null;
                const entriesStats = await store.dispatch("admin/getEntriesStats");
                entriesData.value = entriesStats.data;
            } catch (err) {
                entriesDataError.value = {
                    title: "Oh no!",
                    text: "Qualcosa è andato storto nel recupero delle statistiche sui termini",
                };
            } finally {
                entriesDataLoading.value = false;
            }
        };
        const refreshUsersStats = async () => {
            try {
                usersDataLoading.value = true;
                usersDataError.value = null;
                const usersStats = await store.dispatch("admin/getUsersStats");
                usersData.value = usersStats.data;
            } catch (err) {
                usersDataError.value = {
                    title: "Oh no!",
                    text: "Qualcosa è andato storto nel recupero delle statistiche sugli utenti",
                };
            } finally {
                usersDataLoading.value = false;
            }
        };
        const refreshTagsStats = async () => {
            try {
                tagsDataLoading.value = true;
                tagsDataError.value = null;
                const tagsStats = await store.dispatch("admin/getTagsStats");
                tagsData.value = tagsStats.data;
            } catch (err) {
                tagsDataError.value = {
                    title: "Oh no!",
                    text: "Qualcosa è andato storto nel recupero delle statistiche sui tag",
                };
            } finally {
                tagsDataLoading.value = false;
            }
        };
        const refreshCollaboratorsStats = async () => {
            try {
                collaboratorsDataLoading.value = true;
                collaboratorsDataError.value = null;
                const collaboratorsStats = await store.dispatch("admin/getCollaboratorsStats");
                collaboratorsData.value = collaboratorsStats.data;
            } catch (err) {
                collaboratorsDataError.value = {
                    title: "Oh no!",
                    text: "Qualcosa è andato storto nel recupero delle statistiche sui collaboratori",
                };
            } finally {
                collaboratorsDataLoading.value = false;
            }
        };
        const refreshAssignmentsStats = async () => {
            try {
                assignmentDataLoading.value = true;
                assignmentsDataError.value = null;
                const assignmentsStats = await store.dispatch("admin/getAssignmentsStats",
                    store.getters.loggedUser._id);
                assignmentsData.value = assignmentsStats.data;
            } catch (err) {
                assignmentsDataError.value = {
                    title: "Oh no!",
                    text: "Qualcosa è andato storto nel recupero delle statistiche sui tag",
                };
            } finally {
                assignmentDataLoading.value = false;
            }
        };
        onMounted(async () => {
            await Promise.all([
                refreshEntriesStats(),
                refreshUsersStats(),
                refreshTagsStats(),
                refreshCollaboratorsStats(),
                refreshAssignmentsStats()
            ]);
        });

        return {
            isUserReviewer,
            isUserAdmin,
            entriesData,
            entriesChart,
            entriesDataLoading,
            entriesDataError,
            usersData,
            usersChart,
            usersDataLoading,
            usersDataError,
            tagsData,
            tagsDataLoading,
            tagsDataError,
            collaboratorsData,
            collaboratorsDataLoading,
            collaboratorsDataError,
            assignmentDataLoading,
            assignmentsDataError,
            assignmentsChart,
            assignmentsData,
            onEntriesClick,
            onUsersClick,
            onAssignmentsClick,
            onTagsClick,
            onCollaboratorsClick,
        };
    },
};
</script>
