<template>
  <div>
    <div class="p-d-flex p-ai-center">
      <h1>Termini</h1>
      <!-- Selettore blocchi -->
      <div class="block-selector p-ml-3" style="display: flex; align-items: center;">
          <select 
            class="block-select" 
            style="width: 200px; height: 40px; padding: 10px; margin-left: 10px; margin-right: 20px;"
            @change="onBlockChange"
          >

            <option
              v-for="block in blockOptions"
              :key="block.value"
              :value="block.value"
            >
              {{ block.label }}
            </option>
          </select>
      </div>
      <div> 
          Record filtrati: <span>{{ filteredCount }}</span>
      </div>
      <div class="p-ml-auto" v-if="isUserReviewer">
        <Button
          label="Aggiungi termine"
          icon="pi pi-plus"
          class="p-button-success p-m-1"
          @click="onOpenEntry()"
        />
      </div>
    </div>
    <div class="button-container">
    <!--
    <button
      v-for="block in numBlocks"
      :key="block"
      @click="loadBlock(block)"
      :class="{ 'active': currentBlock === block }"
    >
      {{ (block - 1) * 10000 + 1 }} - {{ block * 10000 }}
    </button>
    -->
  </div>
    <DataTable
      ref="entriesTable"
      :loading="loading"
      :value="filteredEntries"
      :lazy="false"
      :paginator="true"
      :totalRecords="filteredEntries.length"
      dataKey="_id"
      responsiveLayout="scroll"
      :row-hover="true"
      v-model:filters="filters"
      filter-display="menu"
      :rows="rowsPerPage"
      :rows-per-page-options="[10, 25, 50, 100]"
      :first="firstPage"
      @update:rows="updateRowsPerPage"
      @update:first="updateFirstPage"
      @filter="onFilter"
    >
      <Column :exportable="false">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-raised"
            aria-label="Modifica"
            @click="onOpenEntry(slotProps.data._id)"
          />
        </template>
      </Column>
      <Column
        field="jmdictId"
        header="ID JMdict"
        sortable
        :showFilterOperator="false"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="number"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per ID JMdict"
          />
        </template>
      </Column>
      <Column field="kanji" header="Scrittura" sortable>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per kanji"
          />
        </template>
      </Column>
      <Column field="readings" header="Lettura" sortable>
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per lettura"
          />
        </template>
      </Column>
      <Column
        field="meta.version"
        header="Versione"
        sortable
        dataType="numeric"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="number"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per versione"
          />
        </template>
      </Column>
      <Column
        field="state.value"
        header="Stato"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        filterMenuClass="limit-width"
      >
        <template #body="slotProps">
          <Tag
            :value="slotProps.data.state?.label ?? 'N/A'"
            :class="slotProps.data.state?.cssClass ?? ''"
          >
          </Tag>
        </template>
        <template #filter="{ filterModel }">
          <MultiSelect
            v-model="filterModel.value"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Qualunque"
            class="p-column-filter"
          >
          </MultiSelect>
        </template>
      </Column>
      <Column
        field="hasExamples"
        header="Esempi"
        :showFilterOperator="false"
        :showFilterMatchModes="false"
        filterMenuClass="limit-width"
        :filter="true"
      >
        <template #body="slotProps">
          <div class="centered-icon">
            <span v-if="slotProps.data.hasExamples">
              <i class="pi pi-check" style="color: #138762;"></i> <!-- Icona di spunta verde -->
            </span>
            <span v-else>
              <i class="pi pi-times" style="color: #AC0033;"></i> <!-- Icona di x rossa -->
            </span>
          </div>
        </template>

        <!-- Filtro con MultiSelect per true/false -->
        <template #filter="{ filterModel }">
            <MultiSelect
                v-model="filterModel.value"
                :options="exampleOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Qualunque"
                class="p-column-filter"
            >
            </MultiSelect>
        </template>
      </Column>
      <Column
        style="white-space: pre-wrap"
        field="assignee"
        header="Compilatori"
        sortable
        :showFilterOperator="false"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per compilatore"
          />
        </template>
      </Column>
      <Column
        style="white-space: pre-wrap"
        field="reviewers"
        header="Revisori"
        sortable
        :showFilterOperator="false"
        :showFilterMatchModes="false"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Cerca per revisore"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped>
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Spazio tra i pulsanti */
  margin-bottom: 16px; /* Spazio tra i pulsanti e la tabella */
}

.button-container button {
  border: none; /* Rimuove il bordo */
  padding: 8px 12px;
  cursor: pointer;
  background-color: #AC0033; /* Rosso per pulsanti inattivi */
  color: white;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  flex: 1 1 auto;
}

.button-container button.active {
  background-color: #138762; /* Verde per il pulsante attivo */
}

.button-container button:hover:not(.active) {
  background-color: #AC0033; /* Cambia colore al passaggio del mouse */
}

.centered-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<script>
import { isEmpty } from "lodash";
import {
    onMounted, ref, computed, unref,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Tag from "primevue/tag";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import EntryStatus from "@/enums/entryStatus";

export default {
    name: "Entries",
    components: {
        DataTable,
        Column,
        Button,
        Tag,
        MultiSelect,
        InputText,
    },
    setup() {
        /*
        const blockSize = 10000;
        const totalBlocks = ref([]);

        const router = useRouter();
        const store = useStore();

        // Nuove variabili per il caricamento incrementale
        const totalEntries = ref(0);
        //const totalCount = ref(0);
        const entries = ref([]);
        const currentBlock = ref(1);
        const numBlocks = ref(0);
        const numPages = ref(0); // Numero di pagine

        const statuses = ref(Object.values(EntryStatus));
        const isUserReviewer = computed(() => store.getters.hasRoleOrUpper("reviewer"));

        const entriesTable = ref();
        const loading = ref(false);
        const filters = ref({
            jmdictId: {
                value: null,
                matchMode: FilterMatchMode.EQUALS,
            },
            kanji: {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.EQUALS,
                    },
                ],
            },
            readings: {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.EQUALS,
                    },
                ],
            },
            state: {
                value: null,
                matchMode: FilterMatchMode.IN,
            },
            "meta.version": {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
                    },
                ],
            },
            assignee: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS,
            },
            reviewers: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS,
            },
            hasExamples: {
                value: null, // Inizialmente nessun filtro attivo
                matchMode: FilterMatchMode.EQUALS, // Deve filtrare per true/false
            },
        });

        const exampleOptions = ref([
            { label: "Con esempi", value: true },
            { label: "Senza esempi", value: false }
        ]);
        //const queryParams = ref({});
        const queryParams = ref({});

        const displayNames = (entry) => {
            const h = [];
            entry.forEach((a) => {
                h.push(a.fullName);
            });
            return h.join("\n");
        };

        const hasFilters = ref(0);

        const renderEntry = (entry) => {
            const isDeleted = !!entry.meta?.deletedAt;
            const hasItalianSenses = entry.senses.some(
                (sense) => sense.lang === "ita",
            );
            const hasPendingReviews = !isEmpty(entry.meta?.pendingReview) ?? false;
            let state;
            if (isDeleted) {
                state = EntryStatus.DELETED;
            } else if (hasItalianSenses) {
                state = EntryStatus.TRANSLATED;
            } else if (hasPendingReviews) {
                state = EntryStatus.REVIEW_PENDING;
            } else {
                state = EntryStatus.TO_TRANSLATE;
            }
            const hasExamples = entry.senses.some(sense => sense.examples && sense.examples.length > 0);
            return {
                _id: entry._id,
                jmdictId: entry.jmdictId,
                kanji: entry.kanji.length > 0 ? entry.kanji[0].term : "-",
                readings: entry.readings.length > 0 ? entry.readings[0].term : "-",
                meta: {
                    version: (entry.meta?.history.length ?? 0) + 1,
                },
                state,
                hasExamples,
                assignee: entry.assignee.length ? displayNames(entry.assignee) : "",
                reviewers: entry.reviewers.length ? displayNames(entry.reviewers) : "",
            };
        };

        const loadBlock = async (block) => {
            if (block < 1) {
                block = 1;
            }

            //console.log("Valore di entriesTable.value.rows:", entriesTable.value?.rows);
            //console.log("Valore di blockSize:", blockSize);

            currentBlock.value = block;

            queryParams.value = {
                first: (block - 1) * blockSize,
                rows: blockSize,
                sortField: queryParams.value.sortField || null,
                sortOrder: queryParams.value.sortOrder || null,
                filters: filters.value,
            };
            await reloadData();
        };

        const reloadData = async () => {
            //console.log("Caricamento con parametri:", queryParams.value);
            //console.log("First:", queryParams.value.first);
            //console.log("Rows:", queryParams.value.rows);
            //console.log("Filters:", queryParams.value.filters);

            console.log("📡 Inviando richiesta con questi parametri:", JSON.stringify(queryParams.value, null, 2));

            loading.value = true;
            try {
                const response = await store.dispatch(
                    "admin/queryEntries",
                    queryParams.value,
                );
                //console.log("Response Data:", response.data);
                console.log("Dati ricevuti dal server:", response.data);
                console.log("Total Entries:", response.data.totalEntries);
                //console.log("Total Count:", response.data.totalCount);

                entries.value = response.data.entries.map((entry) => renderEntry(entry));
                totalEntries.value = response.data.totalEntries;
                //entries.value[0];

                //calculateNumBlocksAndPages();
            } catch (err) {
                console.error("Errore nel caricamento:", err);
                entries.value = [];
            }
            loading.value = false;
        };

        const onPageChanged = async (event) => {
            //const first = event.first;
            //const rows = event.rows;
            //ueryParams.value.first = first;
            //queryParams.value.rows = rows;
            queryParams.value = event;
            await reloadData();
        };

        const onSort = async (event) => {
            queryParams.value = event;
            await reloadData();
        };

        const onFilter = async () => {
            queryParams.value.first = 0; // Reset della paginazione alla prima pagina

            queryParams.value.filters = filters.value;
            await reloadData();

            const d = unref(filters);
            hasFilters.value = 0;
            for (const [value] of Object.entries(d)) {
                if (value != null) {
                    hasFilters.value = 1;
                }
            }
        };

        const onOpenEntry = (entryId) => {
            if (entryId) {
                router.push({ name: "admin.entries.detail", params: { entryId } });
            } else {
                router.push({ name: "admin.entries.new" });
            }
        };
        
        const onBlockChange = async () => {
          const first = (currentBlock.value - 1) * blockSize;  // Calcola l'offset per il blocco selezionato
          queryParams.value.first = first;

          // Ricarica i dati per il blocco selezionato
          await reloadData();
        };

        onMounted(async () => {
            queryParams.value = {
                first: 0,
                rows: entriesTable.value.rows,
                sortField: null,
                sortOrder: null,
                filters: filters.value,
            };
            await reloadData();

            //calculateTotalBlocks();
            //calculateNumBlocksAndPages();
            //await loadBlock(currentBlock.value);
            //await loadBlock(1);
        });

        return {
            exampleOptions,
            statuses,
            isUserReviewer,
            hasFilters,
            entriesTable,
            loading,
            totalEntries,
            entries,
            filters,
            queryParams,
            onPageChanged,
            onSort,
            onFilter,
            onOpenEntry,
            currentBlock,
            numBlocks,
            loadBlock,
            totalBlocks,
            blockSize,
            onBlockChange
        };
        */
        const blockSize = 50000;
        const numBlocks = ref(0);
        const currentBlock = ref(1);

        const router = useRouter();
        const store = useStore();

        // Stato per gestire i dati
        const allEntries = ref([]); // Memorizza tutto il blocco ricevuto
        const filteredEntries = ref([]); // Dati filtrati da mostrare
        const filteredCount = ref(0);
        const loading = ref(false);

        // Paginazione
        const rowsPerPage = ref(100); // Quante righe mostrare nella tabella
        const firstPage = ref(0); // La prima pagina
        const totalEntries = ref(0); // Numero totale di voci ricevute

        const isUserReviewer = ref(false);

        const statuses = ref(Object.values(EntryStatus));
        //console.log("Valori di EntryStatus:", EntryStatus);

        const renderEntry = (entry) => {
            // Controllo per tutte le proprietà che potrebbero essere undefined
            //console.log("Dati originali entry:", JSON.stringify(entry, null, 2)); // Log di debugging per visualizzare i dati di `entry`

            const isDeleted = !!entry?.meta?.deletedAt;

            // Controlliamo `senses` prima di accedere a `some`
            const hasItalianSenses = entry?.senses?.some(sense => sense?.lang === "ita") || false;
            const hasPendingReviews = !!entry?.meta?.pendingReview;

            let state;
            if (isDeleted) {
                state = EntryStatus.DELETED;
            } else if (hasItalianSenses) {
                state = EntryStatus.TRANSLATED;
            } else if (hasPendingReviews) {
                state = EntryStatus.REVIEW_PENDING;
            } else {
                state = EntryStatus.TO_TRANSLATE;
            }

            // Controlliamo `senses` prima di usare `.some` e `examples`
            const hasExamples = entry?.senses?.some(sense => sense?.examples?.length > 0) || false;

            // Controlliamo `kanji` e `readings` prima di mapparli
            const kanji = entry?.kanji?.length > 0 ? entry.kanji.map(k => k.term).join(", ") : "-";
            const readings = entry?.readings?.length > 0 ? entry.readings.map(r => r.term).join(", ") : "-";

            // Gestiamo `meta.history` in modo sicuro
            const version = (entry?.meta?.history?.length ?? 0) + 1;

            // Restituiamo l'oggetto trasformato
            return {
                _id: entry._id,
                jmdictId: entry.jmdictId,
                kanji,
                readings,
                meta: { version },
                state,
                hasExamples,
                assignee: entry?.assignee?.length ? entry.assignee.map(a => a.fullName).join("\n") : "",
                reviewers: entry?.reviewers?.length ? entry.reviewers.map(r => r.fullName).join("\n") : "",
            };
        };

        // Stato per i filtri
        const queryParams = ref({
            first: 0, // Offset
            rows: blockSize, // Blocchi da 10.000 o 50.000 voci
            filters: {
                jmdictId: { value: null, matchMode: "equals" },
                kanji: { operator: "or", constraints: [{ value: null, matchMode: "contains" }] },
                readings: { operator: "or", constraints: [{ value: null, matchMode: "contains" }] },
                "state.value": { value: null, matchMode: "in" },
                "meta.version": { operator: "or", constraints: [{ value: null, matchMode: "gte" }] },
                assignee: { value: null, matchMode: "contains" },
                reviewers: { value: null, matchMode: "contains" },
                hasExamples: { value: null, matchMode: "equals" } // Filtro per esempi
            }
        });

        const blockOptions = computed(() => {
            const options = [];
            for (let i = 0; i < numBlocks.value; i++) {
                const start = i * blockSize + 1;
                const end = Math.min((i + 1) * blockSize, totalEntries.value);
                options.push({
                    label: `${start} - ${end}`,
                    value: i + 1  // Il valore del blocco è basato su numBlocks
                });
            }
            return options;
        });

        const onBlockChange = async (event) => {
            // Ottieni il valore selezionato dal selettore
            const selectedBlock = parseInt(event.target.value); 
            currentBlock.value = selectedBlock;  // Aggiorna currentBlock manualmente

            // Calcola l'offset per il nuovo blocco
            const first = (selectedBlock - 1) * blockSize;
            queryParams.value.first = first;

            // Ricarica i dati per il blocco selezionato
            await reloadData();

            // Utilizzo di numBlocks in un'altra parte del codice
            //console.log("Numero totale di blocchi:", numBlocks.value);
        };

        // Opzioni per il filtro "hasExamples"
        const exampleOptions = ref([
            { label: "Con esempi", value: true },
            { label: "Senza esempi", value: false }
        ]);

        // Caricamento dati dal backend
        const reloadData = async () => {
            loading.value = true;
            try {
                const response = await store.dispatch("admin/queryEntries", queryParams.value);
                //console.log("Dati ricevuti:", response.data.entries.length);
                //console.log("Totale voci nel DB:", response.data.totalEntries);

                // Salviamo TUTTO il blocco ricevuto
                allEntries.value = response.data.entries.map(entry => renderEntry(entry));
                //allEntries.value = response.data.entries;
                filteredEntries.value = allEntries.value;

                totalEntries.value = response.data.totalEntries;

                numBlocks.value = Math.ceil(totalEntries.value / blockSize); // Numero totale di blocchi
                //console.log("Numero totale blocchi:", numBlocks.value);
            } catch (err) {
                console.error("Errore nel caricamento:", err);
                allEntries.value = [];
                filteredEntries.value = [];
            }
            loading.value = false;
        };

        // Ricarica dati quando cambia la pagina
        const onPageChanged = (event) => {
            queryParams.value.first = event.first;
        };

        // Ricarica dati quando cambiano i filtri
        const onFilterChanged = () => {
            // Nulla
        };

        const updateRowsPerPage = (value) => {
            //console.log("Nuovo valore di rowsPerPage:", value);
            rowsPerPage.value = value;
            firstPage.value = 0; // Reset della paginazione per evitare bug
        };

        const updateFirstPage = (value) => {
            //console.log("Nuovo valore di firstPage:", value);
            firstPage.value = value;
        }

        const goToPage = (pageNumber) => {
            firstPage.value = (pageNumber - 1) * rowsPerPage.value; // Calcolo dell'offset
            //console.log(`Navigo alla pagina ${pageNumber}, offset: ${firstPage.value}`);
        };

        const filters = ref({
            jmdictId: {
                value: null,
                matchMode: FilterMatchMode.EQUALS,
            },
            kanji: {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.EQUALS,
                    },
                ],
            },
            readings: {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.EQUALS,
                    },
                ],
            },
            "state.value": {
                value: null,
                matchMode: FilterMatchMode.IN,
            },
            "meta.version": {
                operator: FilterOperator.OR,
                constraints: [
                    {
                        value: null,
                        matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
                    },
                ],
            },
            assignee: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS,
            },
            reviewers: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS,
            },
            hasExamples: {
                value: null, // Inizialmente nessun filtro attivo
                matchMode: FilterMatchMode.EQUALS, // Deve filtrare per true/false
            },
        });

        const onOpenEntry = (entryId) => {
            if (entryId) {
                router.push({ name: "admin.entries.detail", params: { entryId } });
            } else {
                router.push({ name: "admin.entries.new" });
            }
        };

        const hasActiveFilters = computed(() => {
            return Object.values(filters.value).some(filter => {
                if (filter.operator) {
                    return filter.constraints.some(constraint => constraint.value !== null && constraint.value !== "");
                } else {
                    return filter.value !== null && filter.value !== "";
                }
            });
        });

        const onFilter = (event) => {
            // Contiene il sottoinsieme effettivamente filtrato
            const filteredValue = event.filteredValue || [];
            filteredCount.value = filteredValue.length;
        };

        // Carichiamo i dati all'avvio
        onMounted(async () => {
            currentBlock.value = 1;
            await reloadData();
        });

        return {
            allEntries,
            filteredEntries,
            totalEntries,
            loading,
            queryParams,
            rowsPerPage,
            exampleOptions,
            reloadData,
            onPageChanged,
            onFilterChanged,
            onBlockChange,
            blockOptions,
            updateRowsPerPage,
            updateFirstPage,
            goToPage,
            filters,
            statuses,
            onOpenEntry,
            hasActiveFilters,
            filteredCount,
            onFilter,
            firstPage,
            isUserReviewer
        };
    },
};
</script>
