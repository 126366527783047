<template>
    <footer>
        <p>Copyright © 2022-{{ currentYear }}, Università Ca' Foscari Venezia<!--Made with <i class="bi bi-heart-fill"></i> & <i class="bi bi-cup-hot-fill"></i> by Cafoscari Jisho Team--></p>
        <!--<p>cookie policy</p>-->
        <div class="version">
            <a :class="{ disabled: !hasPrivileges }" :href="hasPrivileges ? '/changelog' : null" title="Ver. 1.1.2">
                Ver. 1.1.2
            </a>
        </div>
    </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
    name: "JFooter",
    setup() {
        const store = useStore();

        // Controlla se l'utente ha almeno il ruolo di revisore o superiore
        const hasPrivileges = computed(() => store.getters.hasRoleOrUpper("compiler"));

        const currentYear = new Date().getFullYear();

        return {
            hasPrivileges,
            currentYear
        };
    }
};
</script>

<style scoped lang="scss">
    @import "src/assets/scss/theme/_variables";
    @import "~primeflex/src/_variables.scss";

    footer {
        width: 100%;
        padding: .8rem;

        display: flex;
        justify-content: space-between;

        font-size: .8rem;
        background-color: $primaryColor;

        p {
            margin: 0;
            padding: 0;
            color: $fontOnPrimary;
        }

        .version {
            color: #FFFFFF;
        }

        .version a {
            text-decoration: none;
            color: #FFFFFF;
        }

        .version a:hover {
            text-decoration: none;
        }

        .version a.disabled {
            color: #FFFFFF; /* Colore grigio */
            cursor: default;
            pointer-events: none; /* Disabilita il click */
            text-decoration: none;
        }
    }
</style>
