<template>
    <Dialog header="Traduzioni" :visible="visible" @update:visible="$emit('update:visible', $event)"
            @hide="$emit('onDialogClose', $event)"
            :closable="!v$.$invalid" :closeOnEscape="!v$.$invalid" :dismissableMask="!v$.$invalid"
            :modal="true" :style="{ width: '50vw' }"
            :breakpoints="{ '992px': '75vw', '768px': '100vw' }">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
                <p>
                    Inserisci qui le tue traduzioni per il medesimo campo semantico.
                    Se il campo semantico è diverso premi il pulsante "Aggiungi campo semantico".
                </p>
            </div>
        </div>
        <JOrderList v-model="glosses" :emptyItem="emptyGloss" :disabled="disabled"
                    :enableInsert="!disabled" addButtonLabel="Aggiungi traduzione">
            <template #header="slotProps">
                <h4>{{ slotProps.item.term ?? `Traduzione ${slotProps.index + 1}` }}</h4>
            </template>
            <template #item="slotProps">
                <JGlossEditor v-model="slotProps.item" :itemKey="slotProps.key" :senseKey="senseKey"
                              :disabled="disabled"/>
            </template>
        </JOrderList>
        <template #footer>
            <Button :label="!disabled ? 'Salva' : 'Chiudi'"
                    :icon="!disabled ? 'pi pi-save' : null"
                    :class="{ 'p-button-success': !disabled }"
                    @click="$emit('update:visible', false)" :disabled="v$.$invalid"/>
        </template>
    </Dialog>
</template>

<script>
import { ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import JGlossEditor from "@/components/admin/entries/JGlossEditor.vue";
import JOrderList from "@/components/JOrderList.vue";

export default {
    name: "JGlossesEditor",
    components: {
        Button,
        Dialog,
        JGlossEditor,
        JOrderList,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Object,
        },
        senseKey: {
            type: [String, Number],
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue", "update:visible", "onDialogClose"],
    setup(props, { emit }) {
        const glosses = ref(props.modelValue);
        let isUpdating = false;

        watch(() => props.modelValue, (newValue) => {
            if (!isUpdating) {
                isUpdating = true;
                glosses.value = newValue;
                isUpdating = false;
            }
        }, { deep: true });

        watch(glosses, (newValue) => {
            if (!isUpdating) {
                //console.log("JGlossesEditor EMETTE update:modelValue:", newValue);
                emit("update:modelValue", newValue);
            }
        }, { deep: true });

        const v$ = useVuelidate();

        const emptyGloss = ref({
            gender: null,
            type: null,
            pri: null,
            term: null,
        });

        return {
            v$,
            glosses,
            emptyGloss,
        };
    },
};
</script>