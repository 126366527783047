<template>
    <main>
      <div class="p-grid no-margin">
        <div class="p-col-10 p-offset-1 p-sm-8 p-sm-offset-2 p-py-5">
          <Card class="p-p-3">
            <template #title>
              <h1 class="p-my-0">Changelog</h1>
            </template>
            <template #content>
              <p>
                Qui puoi trovare le modifiche effettuate nelle diverse versioni del progetto.
                <br>
                <br>
              </p>
  
              <ul>
                <li v-for="(entry, index) in changelogData" :key="index">
                  <strong>{{ entry.version }}</strong><span v-if="entry.date"> - {{ entry.date }}</span>
                  <ul class="changes-list">
                    <li v-for="(change, i) in entry.changes" :key="i">{{ change }}</li>
                  </ul>
                  <br>
                </li>
              </ul>
            </template>
          </Card>
        </div>
      </div>
    </main>
  </template>

<script>
    import Card from "primevue/card";

    export default {
        name: "Changelog",
        components: {
            Card, // Registra il componente
        },
        data() {
            return {
            changelogData: [
                { version: "Ver. 1.1.2", date: "2025/03", changes: [
                    "Corretto un bug che impediva l'aggiunta di campi semantici nel back office",
                    "Aggiornati pacchetti e in particolare Mongoose"
                ] },
                { version: "Ver. 1.1.1", date: "2025/03", changes: [
                    "Corretto un bug che impediva la corretta gestione delle traduzioni nel back office"
                ] },
                { version: "Ver. 1.1.0", date: "2025/03", changes: [
                    "Modificata la logica della tabella 'Termini' nel back office",
                    "Aggiunta la possibilità di editare le voci direttamente dal front-end",
                    "Aggiunta pronuncia dei termini giapponesi",
                    "Aggiunto copyright al footer",
                    "Aggiunto 'Changelog'"
                ] },
                { version: "...", date: "", changes: [
                    ""
                ] },
                { version: "Ver. 1.0.0", date: "2022", changes: [
                    "Prima versione pubblicata"
                ] },
            ],
            };
        },
    };
</script>

<style scoped>
.changelog {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.changes-list {
    margin-top: 8px; /* Aggiunge spazio prima dell'elenco */
}

h1 {
    text-align: center;
    color: black;
}

ul {
    list-style-type: none;
    padding: 0;
}

ul li {
    margin-bottom: 10px;
}

strong {
    color: #333;
}
</style>